import { ref, watch, computed } from 'vue'
import { defineStore } from 'pinia'
import { FetchError } from 'ofetch'
import type {
  AddItemsToCartRequest,
  UpdateBundleItemsRequest,
  CartResponseSuccess,
  ShippingInformationRequest,
} from '@/types/cart'
import { regularItemsBundleMap } from '@/utils/normalize/cartServiceNormalize'
import { useGuestCart } from '~/composables/api/useGuestCart'
import { piniaPluginPersistedstate } from '#imports'

const CATaxSku = 'CA Rx Handling Fee'

export const useGuestCartStore = defineStore(
  'guestCart',
  () => {
    const cartId = ref<string | null>(null)
    const cart = ref<CartResponseSuccess | null>(null)
    const cartApi = useGuestCart()
    const requestError = ref<FetchError | null>(null)
    const loading = ref<boolean>(false)

    const prices = computed(() => cart.value?.prices)
    const itemCount = computed<number>(() => {
      const regularBundleMaps = regularItemsBundleMap(cart.value?.bundle_map)
      return Object.keys(regularBundleMaps ?? {}).length
    })

    const createCart = async () => {
      loading.value = true
      try {
        const { data, error, status } = await cartApi.create()

        // This is a little non-ideal, but when useFetch is called client-side
        // the status and error refs may not be populated immediately so we need
        // to watch the request status
        watch(
          () => status.value,
          () => {
            if (status.value === 'success' && data.value) {
              cartId.value = data.value.cartId
            } else if (status.value === 'error' && error.value) {
              requestError.value = error.value
            }
          },
          { immediate: true },
        )
      } catch (e) {
        /* eslint-disable */
        console.log('[guestCart]: cart create error!')
        console.log(e)
        /* eslint-enable */
      } finally {
        loading.value = false
      }
    }

    const loadCart = async (id?: string) => {
      loading.value = true
      try {
        const { data, error, status } = await cartApi.get((id || cartId.value) ?? '')

        if (data.value) {
          cart.value = data.value
        } else if (error.value) {
          console.log({status: status.value, error: error.value})
          if (error.value.statusCode === 403) {
            await createCart()
          }
          requestError.value = error.value
        }
      } catch (e) {
        /* eslint-disable */
        console.log('[guestCart]: error while loading cart')
        console.log(e)
        /* eslint-enable */
      } finally {
        loading.value = false
      }
    }

    watch(
      () => cartId.value,
      async () => {
        if (cartId.value && !cart.value) {
          await loadCart()
        }
      },
      { immediate: true },
    )

    const initCart = async () => {
      if (!cartId.value) {
        await createCart()
      }
      if (cartId.value && !cart.value) {
        await loadCart()
      }
    }

    const addItems = async (items: AddItemsToCartRequest) => {
      loading.value = true
      try {
        const { data, error } = await cartApi.addItems(cart.value!.id, items)
        if (data.value) {
          cart.value = data.value
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const removeBundle = async (bundleId: string) => {
      // We might have the use case where we have one RX and one NonRx item in the cart
      // and a CA address is added. In this case, we need to remove the CA tax item
      // from the cart if we remove the RX item and there are no other RX items in the cart
      loading.value = true
      try {
        const { data, error } = await cartApi.removeBundle(
          cart.value!.id,
          bundleId,
        )
        if (data.value) {
          cart.value = data.value
          if (
            isShippingToCA.value &&
            !cartHasRxItems.value &&
            caTaxItem.value
          ) {
            await removeCATax()
          }
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const updateBundle = async (
      bundleId: string,
      params: UpdateBundleItemsRequest,
    ) => {
      // when we update the cart and it has CA tax, we need to remove it if there are no RX items
      // or add it if there are RX items
      loading.value = true
      try {
        const { data, error } = await cartApi.updateBundle(
          cart.value!.id,
          bundleId,
          params,
        )
        if (data.value) {
          cart.value = data.value
          if (isShippingToCA.value) {
            if (cartHasRxItems.value && !cartHasCATax.value) {
              await addCATax()
            } else if (!cartHasRxItems.value && cartHasCATax.value) {
              await removeCATax()
            }
          }
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const addShipping = async (params: ShippingInformationRequest) => {
      loading.value = true
      try {
        const { data, error } = await cartApi.addShipping(
          cart.value!.id,
          params,
        )
        if (data.value) {
          cart.value = data.value.cart
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const reset = () => {
      cartId.value = null
      cart.value = null
    }

    const addCATax = async () => {
      if (!cart.value) {
        return
      }
      if (cartHasCATax.value) {
        return
      }
      return await addItems({
        cart_items: [
          {
            sku: CATaxSku,
            quantity: 1,
          },
        ],
        isRegularItem: false,
        isPrescription: false,
        prescription: {
          pd: 0,
          lensType: 'nonprescription',
          isOlderThan12: false,
          od: {
            sphere: 0,
            cylinders: 0,
            axis: 0,
          },
          os: {
            sphere: 0,
            cylinders: 0,
            axis: 0,
          },
        },
      })
    }

    const findBundleWithCaTax = (
      data: CartResponseSuccess['bundle_map'],
    ): string | undefined => {
      for (const [key, value] of Object.entries(data)) {
        if (
          !value.isRegularItem &&
          value.items.some((item) => item.sku === CATaxSku)
        ) {
          return key
        }
      }
      return undefined
    }

    const removeCATax = async () => {
      if (!cart.value) {
        return
      }
      const taxBundle = findBundleWithCaTax(cart.value.bundle_map)
      if (!taxBundle) {
        return
      }
      return await removeBundle(taxBundle)
    }

    const updateOcrRequestId = async (
      ocrRequestId: string | null,
      bundleId?: string,
    ) => {
      loading.value = true
      try {
        const firstPrescriptionBundleWithoutOcr = Object.entries(
          cart.value!.bundle_map,
        )
          .filter(([_, bundle]) => bundle.isPrescription)
          .find(([_, bundle]) => !bundle.prescription.ocrRequestId)

        const bundleToUpdate =
          bundleId ?? firstPrescriptionBundleWithoutOcr?.[0] ?? ''
        const { data, error } = await cartApi.updateOcrRequestId(
          cart.value!.id,
          bundleToUpdate,
          ocrRequestId,
        )
        if (data.value) {
          cart.value = data.value
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const isShippingToCA = computed(() => {
      if (!cart.value?.shipping_addresses?.length) {
        return false
      }
      return (
        (cart.value?.shipping_addresses?.[0]?.country?.code.toLowerCase() ===
          'us' &&
          cart.value?.shipping_addresses?.[0]?.region?.code.toLowerCase() ===
            'ca') ??
        false
      )
    })
    const caTaxItem = computed(() => {
      if (!cart.value?.items) {
        return undefined
      }
      return cart.value.items.find((item) => item.product.sku === CATaxSku)
    })
    const cartHasRxItems = computed(() => {
      if (!cart.value?.bundle_map) {
        return false
      }
      return Object.values(cart.value.bundle_map).some(
        (bundle) => bundle.isPrescription,
      )
    })
    const allItemsHasOcr = computed(() => {
      if (!cart.value?.bundle_map) {
        return false
      }
      return Object.values(cart.value.bundle_map)
        .filter((bundle) => bundle.isPrescription)
        .every((bundle) => bundle.prescription.ocrRequestId)
    })
    const cartHasCATax = computed(() => {
      if (!cart.value?.bundle_map) {
        return false
      }
      return Object.values(cart.value.bundle_map).some(
        (bundle) =>
          !bundle.isRegularItem &&
          bundle.items.some((item) => item.sku === CATaxSku),
      )
    })

    return {
      cart,
      cartId,
      prices,
      itemCount,
      loading,
      isShippingToCA,
      cartHasRxItems,
      allItemsHasOcr,
      cartHasCATax,
      caTaxItem,
      createCart,
      loadCart,
      initCart,
      addItems,
      removeBundle,
      updateBundle,
      addShipping,
      reset,
      addCATax,
      removeCATax,
      updateOcrRequestId,
    }
  },
  {
    persist: [
      {
        storage: piniaPluginPersistedstate.cookies({
          sameSite: 'strict',
        }),
        pick: ['cartId'],
      },
    ],
  },
)
