import validate from "/home/node/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4._3f630899a20d13c6ec8431b7dde863ad/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45persist_45utm_45global from "/home/node/app/rdp-employee/middleware/00.persist-utm.global.ts";
import _01_45guest_45init_45global from "/home/node/app/rdp-employee/middleware/01.guest-init.global.ts";
import _02_45refresh_45guest_45global from "/home/node/app/rdp-employee/middleware/02.refresh-guest.global.ts";
import lens_45step_45global from "/home/node/app/rdp-employee/middleware/lens-step.global.ts";
import manifest_45route_45rule from "/home/node/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4._3f630899a20d13c6ec8431b7dde863ad/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45persist_45utm_45global,
  _01_45guest_45init_45global,
  _02_45refresh_45guest_45global,
  lens_45step_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}