
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93B9VsAbYdsAMeta } from "/home/node/app/rdp-employee/pages/[...slug].vue?macro=true";
import { default as cart_45previewvOJwu0q4VMMeta } from "/home/node/app/rdp-employee/pages/cart-preview.vue?macro=true";
import { default as addressbc51e5M6cyMeta } from "/home/node/app/rdp-employee/pages/checkout/address.vue?macro=true";
import { default as indexqQ5rYckdpmMeta } from "/home/node/app/rdp-employee/pages/checkout/index.vue?macro=true";
import { default as orderconfirmationORFwgZpwCZMeta } from "/home/node/app/rdp-employee/pages/checkout/orderconfirmation.vue?macro=true";
import { default as reviewyIIw1VypFUMeta } from "/home/node/app/rdp-employee/pages/checkout/review.vue?macro=true";
import { default as uploadprescription33aK3cH7cqMeta } from "/home/node/app/rdp-employee/pages/checkout/uploadprescription.vue?macro=true";
import { default as contactImBj16UjafMeta } from "/home/node/app/rdp-employee/pages/contact.vue?macro=true";
import { default as expirediv5p6CvulfMeta } from "/home/node/app/rdp-employee/pages/expired.vue?macro=true";
import { default as indexmtaogVp9SwMeta } from "/home/node/app/rdp-employee/pages/index.vue?macro=true";
import { default as indexn47bcjD9tuMeta } from "/home/node/app/rdp-employee/pages/products/[slug]/index.vue?macro=true";
import { default as indexc2Q1t1U8eeMeta } from "/home/node/app/rdp-employee/pages/products/[slug]/prescription/index.vue?macro=true";
import { default as index575Tk7sGoAMeta } from "/home/node/app/rdp-employee/pages/products/[slug]/select-lens-type/index.vue?macro=true";
import { default as _91_46_46_46slug_93IJyuADHyT6Meta } from "/home/node/app/rdp-employee/pages/products/list/[...slug].vue?macro=true";
import { default as indexSJ62ssjYWDMeta } from "/home/node/app/rdp-employee/pages/products/list/index.vue?macro=true";
import { default as employeePngWEdlzweMeta } from "/home/node/app/rdp-employee/pages/terms-of-use/employee.vue?macro=true";
import { default as indexbbPPw7VpGJMeta } from "/home/node/app/rdp-employee/pages/terms-of-use/employer/index.vue?macro=true";
import { default as v1NMADVPS0N7Meta } from "/home/node/app/rdp-employee/pages/terms-of-use/employer/v1.vue?macro=true";
import { default as v24XApwyZnzUMeta } from "/home/node/app/rdp-employee/pages/terms-of-use/employer/v2.vue?macro=true";
import { default as indexq9uxe4LlgQMeta } from "/home/node/app/rdp-employee/pages/user/order/index.vue?macro=true";
import { default as verifyOBhrpJoqChMeta } from "/home/node/app/rdp-employee/pages/verify.vue?macro=true";
import { default as welcome5EjP5kQ6RbMeta } from "/home/node/app/rdp-employee/pages/welcome.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93B9VsAbYdsAMeta || {},
    component: () => import("/home/node/app/rdp-employee/pages/[...slug].vue")
  },
  {
    name: "cart-preview",
    path: "/cart-preview",
    component: () => import("/home/node/app/rdp-employee/pages/cart-preview.vue")
  },
  {
    name: "checkout-address",
    path: "/checkout/address",
    meta: addressbc51e5M6cyMeta || {},
    component: () => import("/home/node/app/rdp-employee/pages/checkout/address.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexqQ5rYckdpmMeta || {},
    component: () => import("/home/node/app/rdp-employee/pages/checkout/index.vue")
  },
  {
    name: "checkout-orderconfirmation",
    path: "/checkout/orderconfirmation",
    component: () => import("/home/node/app/rdp-employee/pages/checkout/orderconfirmation.vue")
  },
  {
    name: "checkout-review",
    path: "/checkout/review",
    meta: reviewyIIw1VypFUMeta || {},
    component: () => import("/home/node/app/rdp-employee/pages/checkout/review.vue")
  },
  {
    name: "checkout-uploadprescription",
    path: "/checkout/uploadprescription",
    meta: uploadprescription33aK3cH7cqMeta || {},
    component: () => import("/home/node/app/rdp-employee/pages/checkout/uploadprescription.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/node/app/rdp-employee/pages/contact.vue")
  },
  {
    name: "expired",
    path: "/expired",
    meta: expirediv5p6CvulfMeta || {},
    component: () => import("/home/node/app/rdp-employee/pages/expired.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/node/app/rdp-employee/pages/index.vue")
  },
  {
    name: "products-slug",
    path: "/products/:slug()",
    component: () => import("/home/node/app/rdp-employee/pages/products/[slug]/index.vue")
  },
  {
    name: "products-slug-prescription",
    path: "/products/:slug()/prescription",
    meta: indexc2Q1t1U8eeMeta || {},
    component: () => import("/home/node/app/rdp-employee/pages/products/[slug]/prescription/index.vue")
  },
  {
    name: "products-slug-select-lens-type",
    path: "/products/:slug()/select-lens-type",
    meta: index575Tk7sGoAMeta || {},
    component: () => import("/home/node/app/rdp-employee/pages/products/[slug]/select-lens-type/index.vue")
  },
  {
    name: "products-list-slug",
    path: "/products/list/:slug(.*)*",
    component: () => import("/home/node/app/rdp-employee/pages/products/list/[...slug].vue")
  },
  {
    name: "products-list",
    path: "/products/list",
    component: () => import("/home/node/app/rdp-employee/pages/products/list/index.vue")
  },
  {
    name: "terms of use",
    path: "/terms-of-use/employee",
    meta: employeePngWEdlzweMeta || {},
    component: () => import("/home/node/app/rdp-employee/pages/terms-of-use/employee.vue")
  },
  {
    name: "terms of use employer",
    path: "/terms-of-use/employer",
    meta: indexbbPPw7VpGJMeta || {},
    component: () => import("/home/node/app/rdp-employee/pages/terms-of-use/employer/index.vue")
  },
  {
    name: "terms of use employer v1",
    path: "/terms-of-use/employer/v1",
    meta: v1NMADVPS0N7Meta || {},
    component: () => import("/home/node/app/rdp-employee/pages/terms-of-use/employer/v1.vue")
  },
  {
    name: "terms of use employer v2",
    path: "/terms-of-use/employer/v2",
    meta: v24XApwyZnzUMeta || {},
    component: () => import("/home/node/app/rdp-employee/pages/terms-of-use/employer/v2.vue")
  },
  {
    name: "user-order",
    path: "/user/order",
    meta: indexq9uxe4LlgQMeta || {},
    component: () => import("/home/node/app/rdp-employee/pages/user/order/index.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/home/node/app/rdp-employee/pages/verify.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcome5EjP5kQ6RbMeta || {},
    component: () => import("/home/node/app/rdp-employee/pages/welcome.vue")
  }
]